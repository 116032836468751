.color-accommodation {
  background-color: #17a2b8;
}

.color-attraction {
  background-color: #a87600;
}

.color-culture {
  background-color: #ad07ff;
}

.color-nature {
  background-color: #28a745;
}

.color-nutrition {
  background-color: #a22424;
}

.color-recreation {
  background-color: #335f83;
}

.color-shopping {
  background-color: #522000;
}

.color-customer {
  background-color: #fa7a03;
}

.color-season {
  background-color: #860c69;
}

.color-accessible {
  background-color: #090c26;
}

.color-duration {
  background-color: #ff0000;
}

.color-petfriendly {
  background-color: #666633;
}

.color-travel {
  background-color: #33f9ff;
}

.color-experience {
  background-color: #C13693;
}

.color-costless {
  background-color: navy;
}
