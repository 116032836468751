#AppContainer {
  height: 100vh;
  min-height: -moz-available;
  min-height: -webkit-fill-available;
  min-height: strech;
  max-width: 1920px;
}

#main-content {
  padding-top: 65px;
  height: -moz-available;
  height: -webkit-fill-available;
  height: strech;
  overflow-y: auto;
  overflow-x: hidden;
}

.route-view {
  overflow: hidden;
}

.scrollable {
  height: calc(100vh - 100px);
  overflow-y: scroll;
  padding-right: 15px;
}


.nav-vh {
  min-height: 55px;
}

.leaflet-container {
  width: 100%;
  height: calc(100vh - 100px);
  border-radius: 5px;
  border: 2px solid black;
  z-index: 500;
  margin: 0 auto;
}

.sitemap-container .leaflet-container {
  border-radius: 5px;
  margin: 0 auto;
  width: 100%;
  height: 250px;
}

.site-status-buttons {
  width: 100%;
  max-width: 500px;
  font-size: 24pt;
}

.site-thumbnail {
  width: 95%;
  max-width: 512px;
  object-fit: cover;
  border-radius: 5px;
  border: 2px solid white;
}

.site-noimage {
  font-size: 7vh;
}

.route-metrics {
  font-size: 10pt;
  color: white;
  width: 100%;
}

.site-pos {
  width: 30%;
  text-align: center;
}

.route-travelled {
  width: 35%;
  text-align: center;
}

.distance-from-route {
  width: 35%;
  text-align: center;
}

.site-image-primary {
  width: 100%;
  clear: both;
  display: block;
  position: relative;
}

.site-image-primary img {
  border-radius: 12px;
  width: 100%;
}

.hover-pointer-element:hover {
  cursor: pointer;
}

.site-link {
  color: blue;
}

.site-link:hover {
  cursor: pointer;
}

.site-icons {
  font-size: 16pt;
  text-align: center;
  width: 100%;
  margin-top: 10px;
}

.site-icon-dense {
  border: 2px;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  padding: 0px 6px 3px 6px;
  margin-left: 2px;
  margin-top: 2px;
}

.icons-table {
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  margin-bottom: 15px;
}

.site-details-attribute-icon {
  text-align: center;
}

.site-details-attribute-text {
  vertical-align: top;
  text-align: center;
  font-size: 10pt;
}

.site-details-modification-info {
  margin: 0 auto;
  width: 90%;
  min-width: 300px;
  border: 2px solid red;
  border-radius: 5px;
  text-align: center;
  padding: 20px;
}

.site-suggestion {
  background-color: #6c757d;
}

.site-accepted {
  background-color: #28a745;
}

.site-rejected {
  background-color: #ff0000;
}

.typeahead-margin-fix * {
  margin: 0;
}

.rbt-close-content {
  display: none;
}

.textblock {
  text-align: justify;
  padding: 15px;
  hyphens: auto;
}

.leaflet-div-icon {
  border-radius: 50%;
  padding: 5px;
  border: 1px orange solid;
  background-color: orange;
  font-size: 18pt;
  font-weight: bold;
}

.mapmunicipality {
  display: none;
  color: coral;
  text-shadow: 1px 1px #000000;
  background-color: coral;
}

.leaflet-div-icon:hover .mapmunicipality {
  background-color: coral;
  display: block;
}

.yesno {
  width: 100%;
}

#map-div {
  position: sticky;
  top: 5px;
}

.hello-img {
}

.settings-option {
  width: 150px;
}

.collapse-caret {
  float: right;
}

 /* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
} 

.waypoint-field {
  width: 100%;
  float: left;
  border-radius: 3px;
  border: 1px solid lightgray;
}

.waypoint-icon {
  width: 50px;
  float: left;
  text-align: center;
  border-right: 2px solid lightgray;
}

.waypoint-select {
  width: calc(100% - 49px);
  float: right;
}

.select__control {
  border-radius: 0px !important;
}

.settings-buttons {
  float: right;
}

.settings-class-div {
  background-color: lightgray;
  border: 1px solid black;
  border-radius: 5px;
  padding: 10px;
  font-size: 20pt;
  margin-bottom: 15px;
}

.settings-class-div input {
  margin-top: 2px;
  float: right;
  width: 40px;
  height: 40px;
}

.site-image-selector-image {
  height: 6vh;
  border: 5px solid gray;
  border-radius: 15px;
  cursor: pointer;
}

.site-image-selected {
  border: 5px solid blue;
}

.waypoint-ready-entry {
    height: 100%;
    display:flex;
    align-items: center;
}

.waypoint-ready-entry span {
  font-size: 14pt;
  padding-left: 5px;
}

.site-search-result:hover {
  cursor: pointer;
  background: rgba(0,125,125,0.5);
}

.site-suggestion-input-text {
  width: 110px;
}

.site-suggestion-existing-info {
  margin-top: 2px;
  width: 100%;
  border: 1px solid lightgray;
  border-radius: 5px;
  background-color: lightgray;
  min-height: 30px;
  font-size: 12pt;
  padding: 2px 2px 2px 5px;
}

.site-suggestion-input-text-current {
  width: 110px;
  float: left;
  background-color: lightgray;
}
